<template>
  <div class="dictionary">
    <div class="title">
      <span>视频库 / </span> <span class="black">视频上传</span>
    </div>
     <el-card class="condition_style2">
        <el-steps :active="active" simple   align-center >
            <el-step v-for="(item,i) in stepList" :key="i" :title="item.name" @click.native="clickStep(item)"></el-step>
        </el-steps>
        <div class="com_style">
             <component :is="currentTabComponent" @pushUpload="changeActive" :tables="tableList"></component>
        </div>
     </el-card>
        <!-- 上传 -->
    <modal :obj="{
        title:  '上传',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" ref="addForm">
        <el-form-item label="名称：" prop="fileName" >
          <el-input v-model="addForm.fileName" placeholder="请输入名称"   maxlength="18"></el-input>
        </el-form-item>
        <el-form-item label="视频描述" prop="introduce">
            <el-input type="textarea" v-model="addForm.introduce" :rows="4" placeholder="请输入简介" maxlength="300" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="视频文件："  prop="url">
          <div class="flex" >
            <el-upload ref="uploader" :action="action2" accept=".mp4" :headers="headers" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <el-button size="small" type="primary" :loading="loading">{{!loading?"本地上传":"上传中"}}</el-button>
            </el-upload>
            <!-- <span class="upload_span_text">建议上传尺寸为16:9，支持mp4/mp3等格式</span> -->
          </div>
          <!-- <el-progress v-if="loading" :percentage="percentage" color="#409EFF"></el-progress> -->
          <div>
            <video-player v-if="addForm.url" class="video-player vjs-custom-skin" ref="videoPlayer"
              :playsinline="true" :options="playerOptions">
            </video-player>
          </div>
        </el-form-item>
        <!-- <el-form-item label="封面图片：" prop="coverUrl">
          <div class="flex" v-if="minioStyle==0">
            <el-upload :action="action1" accept=".png,.jpg,.jpeg,.bmp,.webp" :headers="headers" :show-file-list="false"
              :on-success="ImgSuccess">
              <el-button size="small" type="primary">本地上传</el-button>
            </el-upload>
            <span class="upload_span_text">建议上传尺寸121pt*92pt，仅支持png/jpg/jpeg/bmp/webp格式</span>
          </div>
          <div v-else>
            <qiniuyun v-if="minioStyle==2" :accept="'.png,.jpg,.jpeg,.bmp,.webp'" :refName="'fileUp2'" :width="80"
              @getUrl="getUrl2">
            </qiniuyun>
            <ossUpload v-else :accept="'.png,.jpg,.jpeg,.bmp,.webp'" :refName="'fileUp2'" :width="80" @getUrl="getUrl2">
            </ossUpload>
            <el-button size="small" type="primary">本地上传</el-button>
            <span class="upload_span_text">建议上传尺寸121pt*92pt，仅支持png/jpg/jpeg/bmp/webp格式</span>
          </div>
          <div class="img_div">
            <img v-if="addForm.coverUrl" :src="addForm.coverUrl" class="img_240" />
          </div>
        </el-form-item> -->
      </el-form>
    </modal>
  </div>
</template>

<script>
import { Container } from 'element-ui';
 import {files2,files1,fileUpdate,fileSave} from "../../../../api/api";
  import modal from "../../../../components/modal";
  import selectVideo from "./selectVideo";
  import videoUpload from "./videoUpload";
  export default {
    components: {
      com1:selectVideo,
      com2:videoUpload,
      modal
    },
    data() {
      return {
        active: 1,
        addDialog: false,
        tableList:[],
        nameList: ["selectVideo", "videoUpload",],
        stepList:[{id:1,name:"请选择文件"},{id:2,name:"视频上传"}],
         addForm: {
          compressionType: "",
          createTime: "",
          extensionName: "",
          fileName: "",
          fileSize: "",
          fileType: "",
          frameRate: "",
          introduce: "",
          mimeType: "",
          url:"",
          height:"",
          width:"",
          compression:"",
          extension: "",
        },
        minioStyle:"",
        name: "", //编辑时获取到的登录账户，用来标志编辑
        role:[],//用来比较角色是否被修改
        action1: files2.url,
        action2: files2.url,
        loading: false, //上传状态
        externalLoading: false, //七牛和阿里上传loading
        percentage: 0, //上传进度
        timer: null, //上传进度定时器
        headers: {
          Accept: "application/json, text/plain, */*",
        //   Authorization: this.$util.getToken("access_token"),
        //   uid: localStorage.getItem('uid') ? localStorage.getItem('uid') : ''
        },
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          autoplay: false, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [{
            type: "video/mp4", // 类型
            src: "", // url地址
          }, ],
          poster: "", // 封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true, // 是否显示全屏按钮
          },
        },
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
      currentTabComponent() {
                return "com" + this.active
    },
    },
    methods: {
    clickStep(i){
        this.active = i.id

    },
    //继续添加视频
    uploadVideo(){
        this.addDialog = true
    },
    changeActive(val){
      this.active = 2
      this.tableList = val
      console.log(val ,"this.tableList ")
    },
    //
    addSubmit(){

    },
    cancel(){

    },
    //完成
    finish(){
      this.$router.push({
        name:"video"
      })
    },
    //开始上传
    submit(){
      this.active = 2

    },
    //  // 上传视频
      handleAvatarSuccess(res, file) {
        if (res.code == "200") {
          this.percentage = 100
          clearInterval(this.timer)
          this.$nextTick(() => {
            this.loading = false
          })
          this.$util.message("suc", "上传成功");
          this.playerOptions.sources = [{
            type: "video/mp4", // 类型
            src: res.data.filename, // url地址
          }, ];
          this.addForm.url = res.data.filename;
          this.addForm.frameRate = res.data.imageInfo.format;
          this.addForm.height = res.data.imageInfo.height;
          this.addForm.width = res.data.imageInfo.width;
          this.addForm.fileType = res.data.fileType;
          this.addForm.mimeType = res.data.mimeType;
          this.addForm.fileSize = res.data.size;
          this.addForm.duration = res.data.duration;
          this.addForm.compressionType = res.data.compression;
          this.addForm.extensionName = res.data.extension;
          this.$refs.addForm.validate((result) => {
            return true
          })
        } else {
          this.$util.message("err", res.message);
          this.loading = false

        }
      },
      //外部上传时
      trueLoading(val) {
        this.externalLoading = val
      },
      // 上传视频-阿里云/七牛云
      getUrl1(val) {
        this.percentage = 100
        clearInterval(this.timer)
        this.$nextTick(() => {
          this.loading = false
          this.externalLoading = false
        })
        this.playerOptions.sources = [{
          type: "video/mp4", // 类型
          src: val, // url地址
        }, ];
        this.addForm.url = val
        this.$refs.addForm.validate((result) => {
          return true
        })
      },
      beforeAvatarUpload(file) {
        this.percentage = 0
        this.addForm.url = ""
        const isLt2M = file.size / 1024 / 1024 < 500;
        if (!isLt2M) {
          this.$util.message("warning", "上传头像图片大小不能超过 500MB!");
        }
        this.loading = true
        this.timer = setInterval(() => {
          if (this.percentage < 95) {
            this.percentage++
          } else {
            clearInterval(this.timer)
          }
        }, 1000);
        return isLt2M;
      },
      // 上传封面图
      ImgSuccess(res, file) {
        if (res.code == "200") {
          this.$util.message("suc", "上传成功");
          this.addForm.coverUrl = res.data && res.data[0];
        } else {
          this.$util.message("err", res.message);
        }
      },
      // 上传封面图-阿里云
      getUrl2(val) {
        this.addForm.coverUrl = val
      },
    },
  };
</script>

<style lang="less" scoped>
  .dictionary {
    /deep/.el-tabs__item {
      width: 152px;
      text-align: center;
    }
    .com_style{
        width: 100%;
    }
    .bottom{
        height: 50px;
        width: 100%;
        text-align: center;
        margin-top: 30px;
    }
  }
</style>